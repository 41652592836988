import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  FormControl
} from "@mui/material";

const AppointmentPopup = ({ open, handleClose, clients, pets, selectedAppointment, refreshAppointments }) => {
  const [error, setError] = useState("");
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedPetId, setSelectedPetId] = useState('');
  const [filteredPets, setFilteredPets] = useState([]);
  const [clientPets, setClientPets] = useState([]);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [groomingDetails, setGroomingDetails] = useState('');
  const [isPickup, setIsPickup] = useState(false);
  const [isDropOff, setIsDropOff] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    if (selectedAppointment) {
      setSelectedClientId(selectedAppointment.clientID.toString());
      setSelectedPetId(selectedAppointment.petID.toString());
      setAppointmentTime(selectedAppointment.appointmentDate);
      setGroomingDetails(selectedAppointment.groomingDetails);
      setIsPickup(selectedAppointment.isPickup);
      setIsDropOff(selectedAppointment.isDropOff);
      setError("")
    } else {
      setSelectedClientId();
      setSelectedPetId();
      setAppointmentTime();
      setGroomingDetails();
      setIsPickup(false);
      setIsDropOff(false);
      setIsPaid(false)
      setError("")
    }
  }, [selectedAppointment]);

  useEffect(() => {
    const fetchClientPets = async () => {
      try {
        const response = await axios.get('https://clawsandpawsapi.azurewebsites.net/api/clientPets');
        setClientPets(response.data);
      } catch (error) {
        console.error('Failed to fetch client-pets relationships:', error);
      }
    };

    fetchClientPets();
  }, []);

  useEffect(() => {
    if (selectedClientId) {
      const filteredPets = pets.filter(pet => 
        clientPets.some(cp => cp.clientID === parseInt(selectedClientId) && cp.petID === pet.petID)
      );
      setFilteredPets(filteredPets);
      setSelectedPetId(filteredPets.length > 0 ? filteredPets[0].petID.toString() : '');
    } else {
      setFilteredPets([]);
      setSelectedPetId('');
    }
  }, [selectedClientId, pets, clientPets]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const appointmentData = {
      appointmentID: selectedAppointment ? selectedAppointment.appointmentID : null,
      clientID: parseInt(selectedClientId),
      petID: parseInt(selectedPetId),
      appointmentDate: appointmentTime,
      isPaid: isPaid,
      isCancelled: false,
      isPickup: isPickup,
      isDropOff: isDropOff,
      groomingDetails: groomingDetails
    };

    try {
      if (selectedAppointment) {
        await axios.put(`https://clawsandpawsapi.azurewebsites.net/api/Appointments/${selectedAppointment.appointmentID}`, appointmentData);
      } else {
        await axios.post('https://clawsandpawsapi.azurewebsites.net/api/Appointments', appointmentData);
      }
      handleClose();
      refreshAppointments();
    } catch (error) {
      console.error("Failed to save appointment:", error);
      setError("Failed to add/edit Appointment.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add/Edit Appointment</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="client-select-label">Client</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={selectedClientId}
              onChange={e => setSelectedClientId(e.target.value)}
            >
              {clients.map((client) => (
                <MenuItem key={client.clientID} value={client.clientID.toString()}>
                  {client.name + " " + client.surname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" disabled={!selectedClientId}>
            <InputLabel id="pet-select-label">Pet</InputLabel>
            <Select
              labelId="pet-select-label"
              id="pet-select"
              value={selectedPetId}
              onChange={e => setSelectedPetId(e.target.value)}
            >
              {filteredPets.map((pet) => (
                <MenuItem key={pet.petID} value={pet.petID.toString()}>
                  {pet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            id="appointment-time"
            label="Appointment Date & Time"
            type="datetime-local"
            value={appointmentTime}
            onChange={e => setAppointmentTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            margin="normal"
            id="grooming-details"
            label="Grooming Details"
            type="text"
            value={groomingDetails}
            onChange={e => setGroomingDetails(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={isPickup} onChange={e => setIsPickup(e.target.checked)} />}
            label="Pickup"
          />
          <FormControlLabel
            control={<Checkbox checked={isDropOff} onChange={e => setIsDropOff(e.target.checked)} />}
            label="Drop off"
          />
          <FormControlLabel
            control={<Checkbox checked={isPaid} onChange={e => setIsPaid(e.target.checked)} />}
            label="Is Paid"
          />
          {error && <p style={{ color: "red" }}>{error}</p>}
        </DialogContent>
        

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {selectedAppointment  &&   <Button type="submit">Edit</Button>}
          {!selectedAppointment  &&    <Button type="submit">Add</Button>}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AppointmentPopup;
