import React, { useState, useEffect } from "react";
import axios from "axios";
import { PRIMARY_COLOR } from "./assets/Colours";
import { TextField, Button, Box, MenuItem, Select } from "@mui/material";

const AddStaff = ({ onStaffAdded }) => {
  const [error, setError] = useState("");
  const [staffData, setStaffData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    permissionID: "",
  });

  useEffect(() => {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaffData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddStaff = async () => {
    await axios.post(
      "https://clawsandpawsapi.azurewebsites.net/api/Credentials/Register?email=" +
        staffData.email +
        "&password=" +
        staffData.password +
        "&firstName=" +
        staffData.firstName +
        "&lastName=" +
        staffData.lastName +
        "&phoneNumber=" +
        staffData.phoneNumber +
        "&permissionID=" +
        staffData.permissionID
    );
    onStaffAdded();
  };

  return (
    <Box>
      <TextField
        label="Email"
        name="email"
        value={staffData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        value={staffData.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="First Name"
        name="firstName"
        value={staffData.firstName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Last Name"
        name="lastName"
        value={staffData.lastName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={staffData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Select
        label="User Type"
        name="permissionID"
        value={staffData.permissionID}
        onChange={handleChange}
        fullWidth
        displayEmpty
        margin="normal"
      >
        <MenuItem value="" disabled>
          Select User Type
        </MenuItem>
        <MenuItem value="2">User</MenuItem>
        <MenuItem value="1">Admin</MenuItem>
      </Select>

      {error && <p style={{ color: "red" }}>{error}</p>}

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddStaff}
        sx={{
          mt: 2,
          bgcolor: PRIMARY_COLOR,
          color: "black",
          fontFamily: "Jua",
          fontWeight: 900,
          textAlign: "left",
          "&:hover": {
            bgcolor: "white",
            color: "#25467B",
            transition: "transform 0.3s",
            transform: "translateY(-5px)",
          },
          "& .MuiListItemIcon-root": {
            color: "#F1AC36",
          },
        }}
      >
        Add User
      </Button>
    </Box>
  );
};

export default AddStaff;
