import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { format } from "date-fns";

const parseDate = (dateString) => {
  const parsedDate = new Date(dateString);
  if (!isNaN(parsedDate)) {
    return parsedDate;
  }
  console.error("Invalid date:", dateString);
  return null;
};

const Sales = ({ businessID }) => {
  const [sales, setSales] = useState([]);
  const [totalSalesCount, setTotalSalesCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salesResponse = await axios.get(
          `https://saleswiftapimanagement.azure-api.net/api/business/${businessID}/sales`
        );
        const salesData = salesResponse.data;

        setSales(salesData);
        setTotalSalesCount(salesData.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [businessID]);

  return (
    <Box sx={{ display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "#25467B", fontWeight: 900 }}
          >
            Sales
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Total Sales Count</Typography>
                  <Typography
                    variant="h4"
                    sx={{ color: "#25467B", fontWeight: 400 }}
                  >
                    {totalSalesCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mt: 4, color: "#25467B", fontWeight: 900 }}
          >
            All Sales
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 800, width: "60vw" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sale ID</TableCell>
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Sale Date</TableCell>
                  <TableCell>Site Location</TableCell>
                  <TableCell>Close Time</TableCell>
                  <TableCell>Final Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales.map((sale) => {
                  const saleDate = parseDate(sale.saleDate);
                  const closeTime = parseDate(sale.closeTime);

                  return (
                    <TableRow key={sale.saleID}>
                      <TableCell>{sale.saleID}</TableCell>
                      <TableCell>{sale.employeeName}</TableCell>
                      <TableCell>
                        {saleDate
                          ? format(saleDate, "MMM dd, yyyy hh:mm a")
                          : "Invalid Date"}
                      </TableCell>
                      <TableCell>{sale.siteLocation}</TableCell>
                      <TableCell>
                        {closeTime
                          ? format(closeTime, "MMM dd, yyyy hh:mm a")
                          : "Invalid Date"}
                      </TableCell>
                      <TableCell>
                        R{(sale.finalAmount || 0).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    </Box>
  );
};

export default Sales;
