import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";

import Sales from "./Sales";
import Appointment from "./Appointments";
import Staff from "./Staff";
import FinancialDashboard from "./FinancialDashboard";
import ClientsMain from "./ClientsMain";
import Calendar from "./Calendar";

function App() {
  const [businessID, setBusinessID] = useState(
    localStorage.getItem("businessID")
  );

  useEffect(() => {
    const storedBusinessID = localStorage.getItem("businessID");
    if (storedBusinessID) {
      setBusinessID(storedBusinessID);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<Login setBusinessID={setBusinessID} />}
        />
        <Route
          path="/dashboard"
          element={
            businessID ? (
              <Dashboard businessID={businessID} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/"
          element={
            businessID ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
        <Route path="/clients" element={businessID ? (<ClientsMain businessID={businessID}/>): (<Navigate to="/login" />) } />

        <Route path="/sales" element={businessID ? (<Sales businessID={businessID} />):(<Navigate to="/login" />)} />
        <Route path="/calendar" element={businessID ? (<Calendar businessID={businessID}/>):(<Navigate to="/login" />) } />
        <Route path="/appointments" element={businessID ? (<Appointment businessID={businessID} />):(<Navigate to="/login" />)} />
        <Route path="/staff" element={businessID ? (<Staff businessID={businessID} />):(<Navigate to="/login" />)} />
        <Route
          path="/financial-dashboard"
          element={
            businessID ? (
              <FinancialDashboard businessID={businessID} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
