import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { format } from "date-fns";
import { PRIMARY_COLOR } from "./assets/Colours";
import AppointmentPopup from "./AppointmentPopup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const parseDate = (dateString) => {
  const parsedDate = new Date(dateString);
  if (!isNaN(parsedDate)) {
    return format(parsedDate, "MMM dd, yyyy hh:mm a");
  }
  console.error("Invalid date:", dateString);
  return "Invalid Date";
};

const StyledTableCell = ({ children }) => (
  <TableCell sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}>
    {children}
  </TableCell>
);

const StyledTableCellHeader = ({ children, sortKey, handleSortChange }) => (
  <TableCell
    sx={{
      fontFamily: "Jua !important",
      borderRight: "1px solid white !important",
      backgroundColor: PRIMARY_COLOR,
      color: "black !important",
      zIndex: 1000,
      position: "sticky",
      top: 0,
      background: PRIMARY_COLOR,
      opacity: 1,
      cursor: "pointer",
    }}
    onClick={() => handleSortChange(sortKey)}
  >
    {children}
  </TableCell>
);

const Appointment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [hasPermission, setHasPermission] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("appointmentDate");
  const [popupOpen, setPopupOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [clients, setClients] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const handleSortChange = (sortKey) => {
    if (sortBy === sortKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(sortKey);
      setSortDirection("asc");
    }
  };
  const handlePopupClose = () => setPopupOpen(false);
  const handlePopupSubmit = (event) => {
    event.preventDefault();
    handlePopupClose();
  };
  const applyFilters = (appointments, clientsData, petsData) => {
    let filteredAppointments = appointments
      .filter((app) => {
        const petDetails =
          petsData.find((pet) => pet.petID === app.petID) || {};
        const clientDetails =
          clientsData.find((client) => client.clientID === app.clientID) || {};
        return (
          (petDetails.name &&
            petDetails.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (clientDetails.name &&
            clientDetails.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      })
      .sort((a, b) => {
        const valueA = a[sortBy] || "";
        const valueB = b[sortBy] || "";

        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortDirection === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
        return 0;
      });

    return filteredAppointments.map((appointment) => {
      const petDetails =
        petsData.find((pet) => pet.petID === appointment.petID) || {};
      const clientDetails =
        clientsData.find(
          (client) => client.clientID === appointment.clientID
        ) || {};
      return {
        ...appointment,
        pet: petDetails,
        client: clientDetails,
      };
    });
  };

  const filteredAndSortedAppointments = useMemo(() => {
    return applyFilters(appointments, clients, pets);
  }, [appointments, clients, pets, searchTerm, sortDirection, sortBy]);

  const fetchClientsAndPets = async () => {
    try {
      const [clientsResponse, petsResponse] = await Promise.all([
        axios.get(`https://clawsandpawsapi.azurewebsites.net/api/Clients`),
        axios.get(`https://clawsandpawsapi.azurewebsites.net/api/Pets`),
      ]);
      setClients(clientsResponse.data);
      setPets(
        petsResponse.data.map((pet) => ({
          ...pet,
          price: pet.price,
        }))
      );
      return [clientsResponse.data, petsResponse.data];
    } catch (error) {
      console.error("Error fetching clients or pets:", error);
    }
  };

  const fetchAppointments = async (clientsData, petsData) => {
    try {
      const { data } = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Appointments`
      );
      const filteredData = data.filter(
        (appointment) => !appointment.isCancelled
      ); // Exclude canceled appointments
      const appointmentsWithDetails = filteredData.map((appointment) => {
        const petDetails =
          petsData.find((pet) => pet.petID === appointment.petID) || {};
        const clientDetails =
          clientsData.find(
            (client) => client.clientID === appointment.clientID
          ) || {};
        return {
          ...appointment,
          pet: petDetails,
          client: clientDetails,
        };
      });
      setAppointments(appointmentsWithDetails);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const handleDeleteAppointment = async () => {
    if (selectedAppointment) {
      try {
        await axios.put(
          `https://clawsandpawsapi.azurewebsites.net/api/Appointments/${selectedAppointment.appointmentID}`,
          {
            ...selectedAppointment,
            isCancelled: true,
          }
        );
        console.log("ID", selectedAppointment.appointmentID);
        handleRefresh();
        setDeleteConfirmOpen(false);
        setSelectedAppointment(null);
      } catch (error) {
        console.error("Failed to cancel appointment:", error);
      }
    }
  };

  useEffect(() => {
    const permissionID = localStorage.getItem("permissionID");
    setHasPermission(permissionID !== "2");
    fetchClientsAndPets().then((data) => {
      if (data) fetchAppointments(data[0], data[1]);
    });
  }, []);
  const handleRefresh = async () => {
    const clientPetData = await fetchClientsAndPets();
    if (clientPetData) {
      fetchAppointments(clientPetData[0], clientPetData[1]);
    }
  };
  const tableHeaders = [
    { title: "Time", key: "appointmentDate" },
    { title: "Client", key: "clientName" },
    { title: "Pet", key: "petName" },
    { title: "Grooming Details", key: "groomingDetails" },
    { title: "Pickup?", key: "isPickup" },
    { title: "Drop off?", key: "isDropOff" },
    { title: "Price", key: "price" },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 2, display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
   
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "black",
              fontFamily: "Jua",
              fontWeight: 900,
              mb: 4,
              position: "absolute",
              left: "350px",
            }}
          >
            Appointments as of {parseDate(new Date().toISOString())}
          </Typography>
        </Box>

        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            label="Search Client or Pet Names"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flex: 1, mr: 2, mt: 3 }}
          />
          <Button
            onClick={() =>
              setSortDirection(sortDirection === "asc" ? "desc" : "asc")
            }
            sx={{
              bgcolor: PRIMARY_COLOR,
              color: "black",
              "&:hover": { bgcolor: "darken(PRIMARY_COLOR, 0.2)" },
              mt: 3,
            }}
          >
            Sort {sortDirection === "asc" ? "Descending" : "Ascending"}
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ width: "100%", maxHeight: "80vh", overflowY: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <StyledTableCellHeader
                    key={header.key}
                    sortKey={header.key}
                    handleSortChange={handleSortChange}
                  >
                    {header.title}
                  </StyledTableCellHeader>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredAndSortedAppointments.map((appointment, index) => (
                <TableRow
                  key={appointment.appointmentID}
                  className="appointment-row"
                  sx={{
                    bgcolor: index % 2 === 0 ? "#f5f5f5" : PRIMARY_COLOR,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#e0f2f1",
                    },
                  }}
                  onClick={() => setSelectedAppointment(appointment)}
                >
                  <StyledTableCell>
                    {parseDate(appointment.appointmentDate)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appointment.client.name || appointment.clientID}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appointment.pet.name || appointment.petID}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appointment.groomingDetails}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appointment.isPickup ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appointment.isDropOff ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appointment.pet.price || "N/A"}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          {hasPermission && (
                <Button
                onClick={() => { setPopupOpen(true); setSelectedAppointment(null); }}
                sx={{
                  bgcolor: PRIMARY_COLOR,
                  color: "white",
                  "&:hover": { bgcolor: "darken(PRIMARY_COLOR, 0.2)" },
                  mb: 2,
                }}
              >
                Add Appointment
              </Button>
          )}

        {selectedAppointment && hasPermission && (
          <Button
            onClick={() => setPopupOpen(true)}
            sx={{
              bgcolor: PRIMARY_COLOR,
              color: "white",
              "&:hover": { bgcolor: "darken(PRIMARY_COLOR, 0.2)" },
              mb: 2,
            }}
          >
            Edit Appointment
          </Button>
        )}
          {selectedAppointment && hasPermission && (
            <Button
              onClick={() => handleDeleteAppointment()}
              sx={{
                bgcolor: "red",
                color: "white",
                "&:hover": { bgcolor: "#b71c1c" },
              }}
            >
              Delete Appointment
            </Button>
          )}
        </Box>

        <AppointmentPopup
          open={popupOpen}
          handleClose={() => {
            setPopupOpen(false);
            setSelectedAppointment(null);
          }}
          handleSubmit={handlePopupSubmit}
          isEditMode={Boolean(selectedAppointment)}
          refreshAppointments={handleRefresh}
          clients={clients}
          pets={pets}
          selectedAppointment={selectedAppointment}
        />

        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to cancel this appointment?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
              No
            </Button>
            <Button onClick={handleDeleteAppointment} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Appointment;
