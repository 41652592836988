import React, { useState, useEffect } from "react";
import axios from "axios";
import { PRIMARY_COLOR } from "./assets/Colours";
import { TextField, Button, Box } from "@mui/material";

const ResetPassword = ({ userID, onPasswordReset }) => {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {}, [userID]);

  const handlePasswordReset = async () => {
    if (newPass !== confirmPass) {
      setError("Passwords do not match");
      return;
    }

    try {
      const payload = {
        id: userID,
        password: newPass,
      };

      await axios.put(
        "https://clawsandpawsapi.azurewebsites.net/api/Credentials/ResetPassword",
        payload
      );

      onPasswordReset();
    } catch (error) {
      console.error("Error editing Credentials:", error);
      setError("Failed to reset password.");
    }
  };

  return (
    <Box>
      <TextField
        label="New Password"
        name="newPassword"
        type="password"
        value={newPass}
        onChange={(e) => setNewPass(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        value={confirmPass}
        onChange={(e) => setConfirmPass(e.target.value)}
        fullWidth
        margin="normal"
      />

      {error && <p style={{ color: "red" }}>{error}</p>}

      <Button
        variant="contained"
        color="primary"
        onClick={handlePasswordReset}
        sx={{
          mt: 2,
          bgcolor: PRIMARY_COLOR,
          color: "black",
          fontFamily: "Jua",
          fontWeight: 900,
          textAlign: "left",
          "&:hover": {
            bgcolor: "white",
            color: "#25467B",
            transition: "transform 0.3s",
            transform: "translateY(-5px)",
          },
          "& .MuiListItemIcon-root": {
            color: "#F1AC36",
          },
        }}
      >
        Reset Password
      </Button>
    </Box>
  );
};

export default ResetPassword;
