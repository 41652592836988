import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../src/assets/Claws&PawsNoBack.png";
import "@fontsource/jua";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./assets/Colours";

const theme = createTheme({
  typography: {
    fontFamily: "Jua, sans-serif",
  },
});

const Login = ({ setBusinessID }) => {
  const [userData, setUserData] = useState({
    userID: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    isActive: false,
    permissionID: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [otpRequested, setOtpRequested] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      if (sessionStorage.getItem("otpRemembered") === "true") {
        await loginUser();
      } else if (!otpRequested) {
        await axios.post(
          "https://clawsandpawsapi.azurewebsites.net/api/OTP/RequestOTP",
          { email }
        );
        setOtpRequested(true);
      } else {
        const otpResponse = await axios.post(
          "https://clawsandpawsapi.azurewebsites.net/api/OTP/VerifyOTP",
          { email, otp }
        );

        if (otpResponse.status === 200) {
          if (rememberDevice) {
            sessionStorage.setItem("otpRemembered", "true");
          }
          await loginUser();
        } else {
          setError("Invalid OTP. Please try again.");
        }
      }
    } catch {
      setError(
        "Failed to complete login. Please check your details and try again."
      );
    }
  };

  const loginUser = async () => {
    try {
      const loginResponse = await axios.post(
        "https://clawsandpawsapi.azurewebsites.net/api/Credentials/Login",
        { email, password }
      );

      if (loginResponse.status === 200) {
        const userResponse = await axios.get(
          `https://clawsandpawsapi.azurewebsites.net/api/Users/${loginResponse.data.userID}`
        );
        setUserData(userResponse.data);
        localStorage.setItem("permissionID", userResponse.data.permissionID);
        const businessID = "79497540-788d-4006-9f62-5c9319f9ba81";
        setBusinessID(businessID);
        localStorage.setItem("businessID", businessID);
        navigate("/dashboard");
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch {
      setError("Failed to complete login. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              background: PRIMARY_COLOR,
              padding: 4,
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box sx={{ flexShrink: 0, mr: 4 }}>
              <img
                src={logo}
                alt="Claws & Paws Logo"
                style={{ width: "350px" }}
              />
            </Box>
            <Box
              component="form"
              onSubmit={handleLogin}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 350,
              }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Login
              </Typography>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              <TextField
                label="Email"
                variant="standard"
                margin="normal"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                label="Password"
                variant="standard"
                type="password"
                margin="normal"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {otpRequested && (
                <>
                  <TextField
                    label="OTP"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberDevice}
                        onChange={(e) => setRememberDevice(e.target.checked)}
                      />
                    }
                    label="Remember this device"
                    sx={{ mt: 1 }}
                  />
                </>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2, width: "60%", backgroundColor: SECONDARY_COLOR }}
              >
                {otpRequested ? "Verify and Login" : "Login"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
