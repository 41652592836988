import React from "react";
import axios from "axios";
import { Button } from "@mui/material";

const DeleteStaff = ({ userID, onStaffDeleted }) => {
  const handleDeleteStaff = async () => {
    try {
      const response = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Users/${userID}`
      );
      const userData = response.data;

      const updatedUserData = { ...userData, isActive: false };

      await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/Users/${userID}`,
        updatedUserData
      );
      onStaffDeleted();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleDeleteStaff}
      sx={{
        mt: 2,
        color: "black",
        fontFamily: "Jua",
        fontWeight: 900,
        textAlign: "left",
        backgroundColor: "#f44336",
      }}
    >
      Deactivate User
    </Button>
  );
};

export default DeleteStaff;
