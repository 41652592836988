import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import {
  Hidden,
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { format } from "date-fns";
import { PRIMARY_COLOR } from "./assets/Colours";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "./firebaseConfig";
import { useDropzone } from 'react-dropzone';

const parseDate = (dateString) => {
  const parsedDate = new Date(dateString);
  return !isNaN(parsedDate)
    ? format(parsedDate, "MMM dd, yyyy hh:mm a")
    : "Invalid Date";
};

const StyledTableCell = ({ children }) => (
  <TableCell sx={{ fontFamily: "Jua", borderRight: "1px solid white" }}>
    {children}
  </TableCell>
);

const StyledTableCellHeader = ({ children }) => (
  <TableCell
    sx={{
      fontFamily: "Jua",
      borderRight: "1px solid white",
      backgroundColor: PRIMARY_COLOR,
      color: "black",
      cursor: "pointer",
    }}
  >
    {children}
  </TableCell>
);

const ClientsMain = () => {
  const defaultProfileImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/clawsandpaws-d515c.appspot.com/o/footprint.png?alt=media&token=701de897-3876-44a7-9b55-f3cd0481a453";
  const [clientInfo, setClientInfo] = useState(null);
  const [error, setError] = useState("");
  const [clientPets, setClientPets] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [lastAppointment, setLastAppointment] = useState(null);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [editingNotes, setEditingNotes] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [newPetPhotoFile, setNewPetPhotoFile] = useState(null); 
  const [editPetPhotoFile, setEditPetPhotoFile] = useState(null); 

  const [allClients, setAllClients] = useState([]);
  const [moreClientsModalOpen, setMoreClientsModalOpen] = useState(false);

  const [editPetSizeID, setEditPetSizeID] = useState("");
  const [editPetNotes, setEditPetNotes] = useState("");
  const [editPetPhoto, setEditPetPhoto] = useState("");
  const [editPetPrice, setEditPetPrice] = useState("");

  const firebaseBucketURL =
    "https://firebasestorage.googleapis.com/v0/b/clawsandpaws-d515c.appspot.com/o/";

  // States for new pet fields
  const [newPetName, setNewPetName] = useState("");
  const [newPetBreed, setNewPetBreed] = useState("");
  const [newPetPrice, setNewPetPrice] = useState("");
  const [newPetSizeID, setNewPetSizeID] = useState("");
  const [newPetNotes, setNewPetNotes] = useState("");
  const [newPetPhoto, setNewPetPhoto] = useState("");
  const [newPetPhotoName, setNewPetPhotoName] = useState("");
  // States for edit pet modal

  const [editPetModalOpen, setEditPetModalOpen] = useState(false);

  const [selectedPet, setSelectedPet] = useState(null);

  const [editPetName, setEditPetName] = useState("");

  const [editPetBreed, setEditPetBreed] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredClients, setFilteredClients] = useState([]);

  // Form state for new client
  const [newClientName, setNewClientName] = useState("");
  const [newClientSurname, setNewClientSurname] = useState("");
  const [newClientAddress, setNewClientAddress] = useState("");
  const [newClientPhoneNumber, setNewClientPhoneNumber] = useState("");
  const [newClientEmail, setNewClientEmail] = useState("");

  // State variable for Add Client Modal visibility
  const [addClientModalOpen, setAddClientModalOpen] = useState(false);
  // New state for delete confirmation modal
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  
  // Function to open the Add Client Modal
  const openAddClientModal = () => {
    setAddClientModalOpen(true);
  };

  // Function to close the Add Client Modal and reset form fields
  const closeAddClientModal = () => {
    setAddClientModalOpen(false);
    resetAddClientForm();
  };

  // Reset form fields for add client modal
  const resetAddClientForm = () => {
    setNewClientName("");
    setNewClientSurname("");
    setNewClientAddress("");
    setNewClientPhoneNumber("");
    setNewClientEmail("");
    setNewPetName("");
    setNewPetBreed("");
  };
  const onDrop = useCallback(acceptedFiles => {
    // Assuming only image is accepted (adjust as necessary)
    setNewPetPhoto(acceptedFiles[0]);
    setNewPetPhotoName(acceptedFiles[0].name)
  }, []);
  useEffect(() => {
    const clientID = 1;

    fetchClientInfo(clientID);
    fetchClientPets(clientID);
    fetchAllClients();
    fetchClientAppointments(clientID);
    fetchSizes();
    selectClient(clientID);
  }, []);

  const selectClient = (clientID) => {
    fetchClientInfo(clientID);
    fetchClientPets(clientID);
    fetchClientAppointments(clientID);
    closeMoreClientsModal();
  };

  const fetchClientInfo = async (clientID) => {
    try {
      const response = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients/${clientID}`
      );
      setClientInfo(response.data);
    } catch (error) {
      console.error("Error fetching client information:", error);
    }
  };

  const fetchClientPets = async (clientID) => {
    try {
      const response = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/ClientPets`
      );
      const petAssociations = response.data;
      const clientPetAssociations = petAssociations.filter(
        (association) => association.clientID === clientID
      );

      const petDetailsPromises = clientPetAssociations.map(
        async (association) => {
          const petResponse = await axios.get(
            `https://clawsandpawsapi.azurewebsites.net/api/Pets/${association.petID}`
          );
          const pet = petResponse.data;
          return {
            ...pet,
            photoURL:
              firebaseBucketURL + encodeURIComponent(pet.photo) + "?alt=media",
          };
        }
      );

      const pets = await Promise.all(petDetailsPromises);
      setClientPets(pets);
    } catch (error) {
      console.error("Error fetching client pets:", error);
    }
  };

  const fetchClientAppointments = async (clientID) => {
    try {
      const response = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Appointments`
      );
      const allAppointments = response.data;

      const clientAppointments = allAppointments.filter(
        (appointment) => appointment.clientID === clientID
      );

      const now = new Date();
      const pastAppointments = clientAppointments
        .filter((appointment) => new Date(appointment.appointmentDate) < now)
        .sort(
          (a, b) => new Date(b.appointmentDate) - new Date(a.appointmentDate)
        );

      const futureAppointments = clientAppointments
        .filter((appointment) => new Date(appointment.appointmentDate) > now)
        .sort(
          (a, b) => new Date(a.appointmentDate) - new Date(b.appointmentDate)
        );

      setLastAppointment(pastAppointments[0] || null);
      setNextAppointment(futureAppointments[0] || null);
      setAppointments(clientAppointments);
    } catch (error) {
      console.error("Error fetching client appointments:", error);
    }
  };

  const fetchSizes = async () => {
    try {
      const response = await axios.get(
        "https://clawsandpawsapi.azurewebsites.net/api/Sizes"
      );
      setSizes(response.data);
    } catch (error) {
      console.error("Error fetching sizes:", error);
    }
  };

  const uploadImage = async (file) => {
    if (!file || file.type !== "image/png") {
      alert("Please upload a PNG image.");
      return null;
    }
  
    const baseFileName = file.name.split(".")[0];
    const fileExtension = ".png";
    let uniqueFileName = `${baseFileName}${fileExtension}`;
  
    // Reference to the images folder in Firebase
    const storageRef = ref(storage, `${uniqueFileName}`);
    let count = 1;
  
    // Check if filename exists and increment if necessary
    while (await imageExists(`${uniqueFileName}`)) {
      uniqueFileName = `${baseFileName}_${count++}${fileExtension}`;
    }
  
    // Upload file with unique filename
    const finalRef = ref(storage, `${uniqueFileName}`);
    await uploadBytes(finalRef, file);
  
    // Return the file's download URL
    const downloadURL = await getDownloadURL(finalRef);
    return downloadURL;
  };
  
  
  const imageExists = async (filePath) => {
    const imagesRef = ref(storage, "images/");
    const imagesList = await listAll(imagesRef);
    return imagesList.items.some((item) => item.fullPath === filePath);
  };
  
  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setNewPetName("");
    setNewPetBreed("");
    setNewPetPrice("");
    setNewPetSizeID("");
    setNewPetNotes("");
    setNewPetPhoto("");
  };

  const handleAddPet = async () => {
    if (!clientInfo || !clientInfo.clientID) {
      console.error("No client selected");
      return;
    }
  
    // Upload the image and get the URL
    const imageUrl = await uploadImage(newPetPhoto);
    if (!imageUrl) return; // If upload failed, exit the function
  
    const newPet = {
      name: newPetName,
      breed: newPetBreed,
      sizeID: newPetSizeID,
      notes: newPetNotes,
      photo: newPetPhotoName, // Save the Firebase image URL
      price: parseFloat(newPetPrice),
    };
  
    try {
      await axios.post(
        `https://clawsandpawsapi.azurewebsites.net/api/Pets?clientID=${clientInfo.clientID}`,
        newPet,
        { headers: { "Content-Type": "application/json" } }
      );
  
      console.log("Pet added and photo uploaded successfully!");
      fetchClientPets(clientInfo.clientID); // Refresh pets list
      closeEditModal(); // Close modal after adding
    } catch (error) {
      console.error("Error adding pet with photo URL:", error);
    }
  };
  
  
  

  // Edit pet modal functions

  const openEditPetModal = () => {
    setEditPetModalOpen(true);
  };

  const closeEditPetModal = () => {
    setEditPetModalOpen(false);

    setSelectedPet(null);

    setEditPetName("");

    setEditPetBreed("");
  };

  // States for edit client modal

  const [editClientModalOpen, setEditClientModalOpen] = useState(false);

  const [editClientName, setEditClientName] = useState("");

  const [editClientAddress, setEditClientAddress] = useState("");

  const [editClientPhoneNumber, setEditClientPhoneNumber] = useState("");

  const [editClientEmail, setEditClientEmail] = useState("");

  const openEditClientModal = () => {
    if (clientInfo) {
      setEditClientName(clientInfo.name);
      setEditClientAddress(clientInfo.address);
      setEditClientPhoneNumber(clientInfo.phoneNumber);
      setEditClientEmail(clientInfo.email);
      setEditClientModalOpen(true);
    }
  };

  const closeEditClientModal = () => {
    setEditClientModalOpen(false);
    setEditClientName("");
    setEditClientAddress("");
    setEditClientPhoneNumber("");
    setEditClientEmail("");
  };

  const handleSaveClient = async () => {
    try {
      const updatedClient = {
        ...clientInfo,
        name: editClientName,
        address: editClientAddress,
        phoneNumber: editClientPhoneNumber,
        email: editClientEmail,
      };

      await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients/${clientInfo.clientID}`,
        updatedClient
      );
      setClientInfo(updatedClient); // Update the displayed client info
      closeEditClientModal();
    } catch (error) {
      console.error("Error updating client information:", error);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSelectPet = (event) => {
    const petID = event.target.value;

    const pet = clientPets.find((p) => p.petID === petID);
    setSelectedPet(pet);

    setEditPetName(pet.name);

    setEditPetBreed(pet.breed);
  };

  const handleSavePet = async () => {
    if (!selectedPet) {
      console.error("No pet selected for editing");
      setError("Failed to edit pet.");
      return;
    }
  
    // Upload the new image if a file is selected
    const imageUrl = editPetPhotoFile ? await uploadImage(editPetPhotoFile) : selectedPet.photo;
  
    const updatedPet = {
      ...selectedPet,
      name: editPetName || selectedPet.name,
      breed: editPetBreed || selectedPet.breed,
      sizeID: editPetSizeID || selectedPet.sizeID,
      notes: editPetNotes || selectedPet.notes,
      photo: imageUrl, // Update photo URL
      price: parseFloat(editPetPrice) || selectedPet.price,
    };
  
    try {
      await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/Pets/${selectedPet.petID}`,
        updatedPet,
        { headers: { "Content-Type": "application/json" } }
      );
  
      // Update pet info in the clientPets state
      setClientPets((prevPets) =>
        prevPets.map((pet) =>
          pet.petID === selectedPet.petID ? { ...pet, ...updatedPet } : pet
        )
      );
  
      closeEditPetModal(); // Close the modal after saving
    } catch (error) {
      console.error(
        "Error updating pet:",
        error.response?.data || error.message
      );
    }
  };
  

  const handleDeletePet = async () => {
    try {
      await axios.delete(
        `https://clawsandpawsapi.azurewebsites.net/api/Pets/${selectedPet.petID}`
      );

      fetchClientPets(1); // Refresh pets list

      closeEditPetModal();
    } catch (error) {
      console.error("Error deleting pet:", error);
    }
  };

  const fetchAllClients = async () => {
    try {
      const response = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients`
      );
      const clients = response.data;

      // For each client, fetch only their associated pets
      const clientsWithPets = await Promise.all(
        clients.map(async (client) => {
          try {
            // Fetch ClientPets for specific client
            const clientPetsResponse = await axios.get(
              `https://clawsandpawsapi.azurewebsites.net/api/ClientPets`
            );
            const clientPetAssociations = clientPetsResponse.data.filter(
              (association) => association.clientID === client.clientID
            );

            // Fetch pet details for the client
            const petDetails = await Promise.all(
              clientPetAssociations.map(async (association) => {
                const petResponse = await axios.get(
                  `https://clawsandpawsapi.azurewebsites.net/api/Pets/${association.petID}`
                );
                return petResponse.data;
              })
            );

            return { ...client, pets: petDetails }; // Add only the client's pets
          } catch (error) {
            console.error(
              "Error fetching pets for client:",
              client.clientID,
              error
            );
            return { ...client, pets: [] }; // Return client with empty pets if error occurs
          }
        })
      );

      setAllClients(clientsWithPets);
      setFilteredClients(clientsWithPets); // Initialize filtered clients
    } catch (error) {
      console.error("Error fetching all clients and their pets:", error);
    }
  };

  useEffect(() => {
    const permissionID = localStorage.getItem("permissionID");
    setHasPermission(permissionID !== "2");
    const filtered = allClients.filter((client) =>
      Object.values(client)
        .concat(
          client.pets.flatMap((pet) => [pet.name, pet.breed]) // Include pet name and breed in search
        )
        .some((val) =>
          val.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    setFilteredClients(filtered);
  }, [searchTerm, allClients]);

  const openMoreClientsModal = () => setMoreClientsModalOpen(true);
  const closeMoreClientsModal = () => {
    setMoreClientsModalOpen(false);
    setEditClientModalOpen(false); // Close edit client modal if open
    setEditPetModalOpen(false); // Close edit pet modal if open
    setEditModalOpen(false); // Close add pet modal if open
  };
  const handleAddClient = async () => {
    const client = {
      name: newClientName,
      surname: newClientSurname,
      address: newClientAddress,
      phoneNumber: newClientPhoneNumber,
      email: newClientEmail,
    };

    try {
      const response = await axios.post(
        "https://clawsandpawsapi.azurewebsites.net/api/Clients",
        client,
        { headers: { "Content-Type": "application/json" } }
      );
    } catch (error) {
      console.error("Error adding new client:", error);
    }
  };

  const handleDeleteClient = async () => {
    try {
      // Delete all pets associated with the client
      await Promise.all(
        clientPets.map(async (pet) => {
          await axios.delete(
            `https://clawsandpawsapi.azurewebsites.net/api/Pets/${pet.petID}`
          );
        })
      );

      // Delete the client
      await axios.delete(
        `https://clawsandpawsapi.azurewebsites.net/api/Clients/${clientInfo.clientID}`
      );

      // Close the modal and refresh the client list if needed
      setConfirmDeleteModalOpen(false);
      setAddClientModalOpen(false);
      // Optionally refresh the client list
    } catch (error) {
      console.error("Error deleting client and pets:", error);
    }
  };

  

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 2,
        display: "flex",
        fontFamily: "Jua, sans-serif",
        position: "relative",
      }}
    >
      <Hidden mdDown>
        <Sidebar />
      </Hidden>
      <Box sx={{ flexGrow: 1, marginLeft: { md: "0px" }, padding: 2 }}>
        {/* More Clients Button */}
        <Button 
          variant="contained"
          sx={{ position: "absolute",  top: "45px", right: 150, fontFamily: "Jua" }}
          onClick={openMoreClientsModal}
        >
          More Clients
        </Button>
        {/* Add Client Button */}
        {hasPermission && (
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              top: "45px",
              right: 300,
              fontFamily: "Jua",
            }}
            onClick={openAddClientModal}
          >
            Add Client
          </Button>
        )}

        <Typography
          variant="h4"
          sx={{
            fontFamily: "Jua",
            position: "absolute",
            top: "40px",
            left: "350px",
          }}
        >
          Client Card
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: 8 }}>
          <Grid item xs={12} md={5.93}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: PRIMARY_COLOR,
                borderRadius: 2,
                minHeight: "200px",
                maxHeight: "400px",
                overflowY: "auto",
                position: "relative", // For positioning the edit button
              }}
            >
              {/* Title and Edit Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" sx={{ fontFamily: "Jua, sans-serif" }}>
                  Client Information
                </Typography>
                {hasPermission && (
                  <IconButton
                    onClick={openEditClientModal}
                    sx={{ color: "white" }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>

              {/* Content with Image and Information */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* Left Side: Client Image */}
                <Box
                  sx={{
                    marginRight: 2,
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={
                      clientInfo && clientInfo.photoURL
                        ? clientInfo.photoURL
                        : defaultProfileImageUrl
                    }
                    alt="Client"
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                {/* Right Side: Client Information */}
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "Jua, sans-serif" }}
                  >
                    {clientInfo
                      ? `${clientInfo.name} ${clientInfo.surname}`
                      : "Loading..."}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Jua, sans-serif" }}
                  >
                    <strong>Address:</strong>{" "}
                    {clientInfo ? clientInfo.address : "N/A"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Jua, sans-serif" }}
                  >
                    <strong>Phone Number:</strong>{" "}
                    {clientInfo ? clientInfo.phoneNumber : "N/A"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Jua, sans-serif" }}
                  >
                    <strong>Email:</strong>{" "}
                    {clientInfo ? clientInfo.email : "N/A"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid marginLeft={1} item xs={12} md={2}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: PRIMARY_COLOR,
                borderRadius: 2,
                minHeight: "215px",
                maxHeight: "400px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 5,
                  alignSelf: "flex-start",
                  fontFamily: "Jua, sans-serif",
                  textAlign: "center",
                }}
              >
                Next Appointment
              </Typography>
              {nextAppointment ? (
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", fontFamily: "Jua, sans-serif" }}
                >
                  {parseDate(nextAppointment.appointmentDate)}
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", fontFamily: "Jua, sans-serif" }}
                >
                  N/A
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid marginLeft={2} item xs={12} md={3}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: PRIMARY_COLOR,
                borderRadius: 2,
                minHeight: "215px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 5,
                  alignSelf: "flex-start",
                  fontFamily: "Jua, sans-serif",
                  textAlign: "center",
                }}
              >
                Last Appointment
              </Typography>
              {lastAppointment ? (
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", fontFamily: "Jua, sans-serif" }}
                >
                  {parseDate(lastAppointment.appointmentDate)}
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", fontFamily: "Jua, sans-serif" }}
                >
                  N/A
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: PRIMARY_COLOR,
                borderRadius: 2,
                minHeight: "500px",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Static Title Section */}
              <Typography
                variant="h5"
                sx={{ marginBottom: 1, fontFamily: "Jua" }}
              >
                Previous Appointments
              </Typography>

              {/* Scrollable Table Section */}
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  maxHeight: "400px", // Adjust max height to control scrollable area
                  marginTop: 2, // Add margin if needed to separate title and table
                }}
              >
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCellHeader>Date</StyledTableCellHeader>
                        <StyledTableCellHeader>Pet Name</StyledTableCellHeader>
                        <StyledTableCellHeader>
                          Grooming Details
                        </StyledTableCellHeader>
                        <StyledTableCellHeader>Price</StyledTableCellHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointments.map((appointment, index) => {
                        const pet = clientPets.find(
                          (pet) => pet.petID === appointment.petID
                        );
                        return (
                          <TableRow
                            key={appointment.appointmentID}
                            sx={{
                              bgcolor:
                                index % 2 === 0 ? "#f5f5f5" : PRIMARY_COLOR,
                              cursor: "pointer",
                            }}
                          >
                            <StyledTableCell>
                              {parseDate(appointment.appointmentDate)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {pet ? pet.name : "Unknown"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {appointment.groomingDetails || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {pet ? `R ${pet.price}` : "N/A"}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>

          <Grid marginLeft={2} item xs={12} md={3}>
            <Box
              sx={{
                position: "relative",
                padding: 2,
                backgroundColor: PRIMARY_COLOR,
                borderRadius: 2,
                minHeight: "200px",
                textAlign: "left",
                maxHeight: 500,
                overflowY: "auto",
              }}
            >
              {hasPermission && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 40,
                    color: "white",
                  }}
                  onClick={openEditPetModal}
                >
                  <EditIcon />
                </IconButton>
              )}
              {hasPermission && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "white",
                  }}
                  onClick={openEditModal}
                >
                  <AddIcon />
                </IconButton>
              )}
              <Typography variant="h6" sx={{ fontFamily: "Jua" }}>
                Pets
              </Typography>
              {clientPets.length > 0 ? (
                clientPets.map((pet) => (
                  <Box
                    key={pet.petID}
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      display: "flex",
                      justifyContent: "space-between", // Align text and image at ends
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    {/* Pet Details - Left Column */}
                    <Box sx={{ display: "flex", flexDirection: "column", fontFamily: "Jua, sans-serif" }}>
                      <Typography variant="body1 ">
                        <strong>Name:</strong> {pet.name}
                      </Typography>
                      <Typography variant="body2" fontFamily={"Jua, sans-serif"}>
                        <strong>Breed:</strong> {pet.breed}
                      </Typography>
                    </Box>
                    {/* Pet Image - Right */}
                    <Box sx={{ marginLeft: 2 }}>
                    <img src={pet.photoURL} alt={`${pet.name}`} style={{ width: "60px", height: "60px", borderRadius: "30px" }} />

                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1">
                  No pets found for this client.
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Add Client Modal */}
          <Modal open={addClientModalOpen} onClose={closeAddClientModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400, // Reduced width since we only have client fields now
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontFamily: "Jua" }}>
                Add New Client
              </Typography>
              <TextField
                label="Name"
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Surname"
                value={newClientSurname}
                onChange={(e) => setNewClientSurname(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Phone Number"
                value={newClientPhoneNumber}
                onChange={(e) => setNewClientPhoneNumber(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Email"
                value={newClientEmail}
                onChange={(e) => setNewClientEmail(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Address"
                value={newClientAddress}
                onChange={(e) => setNewClientAddress(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAddClient}
                  disabled={
                    !newClientName || !newClientSurname || !newClientPhoneNumber
                  }
                >
                  Add Client
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeAddClientModal}
                >
                  Discard
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* More Clients Modal */}
          <Modal open={moreClientsModalOpen} onClose={closeMoreClientsModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 1400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontFamily: "Jua" }}>
                All Clients
              </Typography>

              <TextField
                label="Search Clients"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
              />

              <TableContainer
                component={Paper}
                sx={{ maxHeight: 500, overflowY: "auto " }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCellHeader>ID</StyledTableCellHeader>
                      <StyledTableCellHeader>Name</StyledTableCellHeader>
                      <StyledTableCellHeader>Surname</StyledTableCellHeader>
                      <StyledTableCellHeader>
                        Phone Number
                      </StyledTableCellHeader>
                      <StyledTableCellHeader>Email</StyledTableCellHeader>
                      <StyledTableCellHeader>Address</StyledTableCellHeader>
                      <StyledTableCellHeader>Pet Name</StyledTableCellHeader>
                      <StyledTableCellHeader>Pet Breed</StyledTableCellHeader>
                      <StyledTableCellHeader>Select</StyledTableCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredClients.map((client) => (
                      <TableRow key={client.clientID}>
                        <StyledTableCell>{client.clientID}</StyledTableCell>
                        <StyledTableCell>{client.name}</StyledTableCell>
                        <StyledTableCell>{client.surname}</StyledTableCell>
                        <StyledTableCell>{client.phoneNumber}</StyledTableCell>
                        <StyledTableCell>{client.email}</StyledTableCell>
                        <StyledTableCell>{client.address}</StyledTableCell>
                        <StyledTableCell>
                          {client.pets && client.pets.length > 0
                            ? client.pets.map((pet) => pet.name).join(", ")
                            : "No Pets"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {client.pets && client.pets.length > 0
                            ? client.pets.map((pet) => pet.breed).join(", ")
                            : "No Pets"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            onClick={() => selectClient(client.clientID)}
                            variant="contained"
                            size="small"
                          >
                            Select
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="contained"
                sx={{ mt: 2, fontFamily: "Jua" }}
                onClick={closeMoreClientsModal}
              >
                Close
              </Button>
            </Box>
          </Modal>

          {/* Delete Confirmation Modal */}
          <Modal
            open={confirmDeleteModalOpen}
            onClose={() => setConfirmDeleteModalOpen(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontFamily: "Jua" }}>
                Are you sure you want to delete this client?
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteClient}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setConfirmDeleteModalOpen(false)}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Edit Client Modal */}

          <Modal open={editClientModalOpen} onClose={closeEditClientModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 2, fontFamily: "Jua, sans-serif" }}
              >
                Edit Client Information
              </Typography>

              <TextField
                fontFamily="Jua, sans-serif"
                label="Name"
                value={editClientName}
                onChange={(e) => setEditClientName(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              <TextField
                label="Address"
                value={editClientAddress}
                onChange={(e) => setEditClientAddress(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              <TextField
                label="Phone Number"
                value={editClientPhoneNumber}
                onChange={(e) => setEditClientPhoneNumber(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              <TextField
                label="Email"
                value={editClientEmail}
                onChange={(e) => setEditClientEmail(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClient}
                >
                  Save
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeEditClientModal}
                >
                  Discard
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Edit Pet Modal */}
          <Modal open={editPetModalOpen} onClose={closeEditPetModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontFamily: "Jua" }}>
                Edit Pet
              </Typography>

              {/* Pet Selection Dropdown */}
              <Select
                value={selectedPet ? selectedPet.petID : ""}
                onChange={(e) => {
                  const petID = e.target.value;
                  const pet = clientPets.find((p) => p.petID === petID);
                  if (pet) {
                    setSelectedPet(pet);
                    setEditPetName(pet.name);
                    setEditPetBreed(pet.breed);
                    setEditPetSizeID(pet.sizeID);
                    setEditPetNotes(pet.notes);
                    setEditPetPhoto(pet.photo);
                    setEditPetPrice(pet.price.toString());
                  }
                }}
                displayEmpty
                fullWidth
                sx={{ mb: 2 }}
              >
                <MenuItem value="" disabled>
                  Select Pet
                </MenuItem>
                {clientPets.map((pet) => (
                  <MenuItem key={pet.petID} value={pet.petID}>
                    {pet.name}
                  </MenuItem>
                ))}
              </Select>

              {/* Name Field */}
              <TextField
                label="Name"
                value={editPetName}
                onChange={(e) => setEditPetName(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              {/* Breed Field */}
              <TextField
                label="Breed"
                value={editPetBreed}
                onChange={(e) => setEditPetBreed(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              {/* Size Selection */}
              <Select
                label="Size"
                value={editPetSizeID}
                onChange={(e) => setEditPetSizeID(e.target.value)}
                fullWidth
                displayEmpty
                sx={{ mb: 2 }}
              >
                <MenuItem value="" disabled>
                  Select Size
                </MenuItem>
                {sizes.map((size) => (
                  <MenuItem key={size.sizeID} value={size.sizeID}>
                    {size.name}
                  </MenuItem>
                ))}
              </Select>

              {/* Notes Field */}
              <TextField
                label="Notes"
                value={editPetNotes}
                onChange={(e) => setEditPetNotes(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />

              {/* Photo Field */}
              <TextField
  type="file"
  accept="image/png"
  onChange={(e) => setNewPetPhotoFile(e.target.files[0])}
  fullWidth
  sx={{ mb: 2 }}
/>


              {/* Price Field */}
              <TextField
                label="Price"
                value={editPetPrice}
                onChange={(e) => setEditPetPrice(e.target.value)}
                fullWidth
                type="number"
                sx={{ mb: 2 }}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSavePet}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeEditPetModal}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeletePet}
                >
                  Delete
                </Button>

              </Box>
            </Box>
          </Modal>

          {/* Add New Pet Modal */}
          <Modal open={editModalOpen} onClose={closeEditModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontFamily: "Jua" }}>
                Add New Pet
              </Typography>
              <TextField
                label="Name"
                value={newPetName}
                onChange={(e) => setNewPetName(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Breed"
                value={newPetBreed}
                onChange={(e) => setNewPetBreed(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Price"
                value={newPetPrice}
                onChange={(e) => setNewPetPrice(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
                type="number"
              />
              <Select
                value={newPetSizeID}
                onChange={(e) => setNewPetSizeID(e.target.value)}
                fullWidth
                displayEmpty
                sx={{ mb: 2 }}
              >
                <MenuItem value="" disabled>
                  Select Size
                </MenuItem>
                {sizes.map((size) => (
                  <MenuItem key={size.sizeID} value={size.sizeID}>
                    {size.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                label="Notes"
                value={newPetNotes}
                onChange={(e) => setNewPetNotes(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Photo URL"
                value={newPetPhotoName}
                onChange={(e) => setNewPetPhoto(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
  <Box {...getRootProps()} sx={{ border: '1px dashed gray', p: 2, mb: 2, cursor: 'pointer' }}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
        </Box>
              <Button
                variant="contained"
                color="success"
                onClick={handleAddPet}
                fullWidth
                sx={{ mt: 3 }}
              >
                Add New Pet
              </Button>
            </Box>
          </Modal>
        </Grid>
      </Box>
    </Box>
  );
};

export default ClientsMain;
