import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Button,
  ButtonGroup,
  Hidden,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import Sidebar from "./Sidebar";
import { format, subDays, startOfMonth } from "date-fns";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const parseDate = (dateString) => {
  if (!dateString) {
    console.error("Date string is null or undefined:", dateString);
    return null;
  }

  const parsedDate = new Date(dateString);

  if (isNaN(parsedDate.getTime())) {
    console.error("Invalid date:", dateString);
    return null;
  }

  return parsedDate;
};

const FinancialDashboard = () => {
  const navigate = useNavigate();
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesCount, setTotalSalesCount] = useState(0);
  const [weeklySales, setWeeklySales] = useState(0);
  const [monthlySales, setMonthlySales] = useState(0);
  const [yearlySales, setYearlySales] = useState(0);
  const [filter, setFilter] = useState("lifetime");
  const [salesData, setSalesData] = useState({
    labels: [],
    datasets: [
      {
        label: "Sales",
        data: [],
        fill: false,
        borderColor: "#2BAEA1",
        tension: 0.1,
      },
    ],
  });
  const chartRef = useRef(null);
  const fetchData = async () => {
    try {
      const salesResponse = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Appointments/lifetime-total`
      );
      const { appointments, lifetimeTotalPrice } = salesResponse.data;

      const totalAmount = lifetimeTotalPrice || 0;

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const oneWeekAgo = subDays(new Date(), 7);

      const weeklyAmount = appointments
        .filter((appointment) => {
          const saleDate = parseDate(appointment.appointmentDate);
          return saleDate && saleDate >= oneWeekAgo;
        })
        .reduce((acc, appointment) => acc + (appointment.price || 0), 0);

      const monthlyAmount = appointments
        .filter((appointment) => {
          const saleDate = parseDate(appointment.appointmentDate);
          return (
            saleDate &&
            saleDate.getMonth() === currentMonth &&
            saleDate.getFullYear() === currentYear
          );
        })
        .reduce((acc, appointment) => acc + (appointment.price || 0), 0);

      const yearlyAmount = appointments
        .filter((appointment) => {
          const saleDate = parseDate(appointment.appointmentDate);
          return saleDate && saleDate.getFullYear() === currentYear;
        })
        .reduce((acc, appointment) => acc + (appointment.price || 0), 0);

      setTotalSales(totalAmount);
      setTotalSalesCount(appointments.length);
      setWeeklySales(weeklyAmount);
      setMonthlySales(monthlyAmount);
      setYearlySales(yearlyAmount);

      updateChartData(appointments, filter);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF("p", "pt", "a4");

    const headerColor = "#2BAE9E";
    const textColor = "#333";
    const highlightColor = "#FF6347";

    // Title Section
    doc.setFontSize(24);
    doc.setTextColor(headerColor);
    doc.text("Financial Dashboard Report", 40, 40);
    doc.setTextColor(textColor);
    doc.setFontSize(12);
    doc.text(
      `Generated on: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
      40,
      60
    );
    doc.setDrawColor(headerColor);
    doc.line(40, 80, 550, 80);

    const metricsFontSize = 16;
    const metrics = [
      {
        label: "Total Revenue",
        value: `R${totalSales.toFixed(2)}`,
        color: headerColor,
      },
      {
        label: "Total Appointments",
        value: totalSalesCount.toString(),
        color: highlightColor,
      },
      {
        label: "Weekly Sales",
        value: `R${weeklySales.toFixed(2)}`,
        color: headerColor,
      },
      {
        label: "Monthly Sales",
        value: `R${monthlySales.toFixed(2)}`,
        color: highlightColor,
      },
      {
        label: "Yearly Sales",
        value: `R${yearlySales.toFixed(2)}`,
        color: headerColor,
      },
    ];

    let currentY = 100;
    metrics.forEach((metric, index) => {
      const yOffset = 24 * index;
      doc.setFontSize(metricsFontSize);
      doc.setTextColor(metric.color);
      doc.text(metric.label, 40, currentY + yOffset);
      doc.setTextColor(textColor);
      doc.text(String(metric.value), 200, currentY + yOffset);
    });

    currentY += metrics.length * 24 + 20;
    doc.setDrawColor(headerColor);
    doc.line(40, currentY, 550, currentY);

    if (chartRef.current) {
      html2canvas(chartRef.current, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          doc.setFontSize(18);
          doc.setTextColor(headerColor);
          doc.text("Sales Trend", 40, currentY + 30);
          doc.addImage(imgData, "PNG", 40, currentY + 50, 500, 300);

          doc.setFontSize(10);
          doc.setTextColor("#777");
          doc.text(
            `Report generated by Claws and Paws - ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
            40,
            780
          );

          doc.save("Financial_Dashboard_Report.pdf");
        })
        .catch((error) => {
          console.error("Error capturing chart with html2canvas:", error);
        });
    } else {
      console.error("chartRef is not attached to any element");
    }
  };

  const updateChartData = (appointments, filter) => {
    let filteredAppointments = appointments;

    if (filter === "week") {
      const oneWeekAgo = subDays(new Date(), 7);
      filteredAppointments = appointments.filter(
        (appointment) => parseDate(appointment.appointmentDate) >= oneWeekAgo
      );
    } else if (filter === "month") {
      const startOfCurrentMonth = startOfMonth(new Date());
      filteredAppointments = appointments.filter(
        (appointment) =>
          parseDate(appointment.appointmentDate) >= startOfCurrentMonth
      );
    }

    const dailySales = filteredAppointments.reduce((acc, appointment) => {
      const date = parseDate(appointment.appointmentDate);
      if (date) {
        const day = format(date, "yyyy-MM-dd");
        if (!acc[day]) {
          acc[day] = 0;
        }
        acc[day] += appointment.price || 0;
      }
      return acc;
    }, {});

    const sortedDates = Object.keys(dailySales).sort();
    const chartLabels = sortedDates.map((date) =>
      format(new Date(date), "MMM dd, yyyy")
    );
    const chartData = sortedDates.map((date) => dailySales[date]);

    setSalesData({
      labels: chartLabels,
      datasets: [
        {
          label: "Sales",
          data: chartData,
          fill: false,
          borderColor: "#2BAEA1",
          tension: 0.1,
        },
      ],
    });
  };

  useEffect(() => {
    const permissionID = localStorage.getItem("permissionID");
    if (permissionID != "1") {
      navigate("/dashboard");
      return;
    }
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filter]);

  return (
    <Box sx={{ display: "flex" }}>
      <Hidden mdDown>
        <Sidebar />
      </Hidden>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          ml: { xs: 0, md: 4 },
        }}
      >
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "black", fontWeight: 900, fontFamily: "Jua" }}
          >
            Financial Dashboard
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: "Jua" }}>
                    Total Revenue
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#2BAE9E",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    R{totalSales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: "Jua" }}>
                    Total Appointments
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#2BAE9E",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    {totalSalesCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: "Jua" }}>
                    Weekly Sales
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#2BAE9E",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    R{weeklySales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: "Jua" }}>
                    Monthly Sales
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#2BAE9E",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    R{monthlySales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: "Jua" }}>
                    Yearly Sales
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#2BAE9E",
                      fontWeight: 400,
                      fontFamily: "Jua",
                    }}
                  >
                    R{yearlySales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <ButtonGroup sx={{ mt: 3 }}>
            <Button
              variant={filter === "lifetime" ? "contained" : "outlined"}
              onClick={() => setFilter("lifetime")}
              sx={{
                fontFamily: "Jua",
                bgcolor: filter === "lifetime" ? "#2BAE9E" : "inherit",
                color: filter === "lifetime" ? "white" : "inherit",
                "&:hover": {
                  bgcolor: "#2BAE9E",
                  color: "white",
                  borderColor: "#2BAE9E",
                },
              }}
            >
              Lifetime
            </Button>
            <Button
              variant={filter === "month" ? "contained" : "outlined"}
              onClick={() => setFilter("month")}
              sx={{
                fontFamily: "Jua",
                bgcolor: filter === "month" ? "#2BAE9E" : "inherit",
                color: filter === "month" ? "white" : "inherit",
                "&:hover": {
                  bgcolor: "#2BAE9E",
                  color: "white",
                  borderColor: "#2BAE9E",
                },
              }}
            >
              This Month
            </Button>
            <Button
              variant={filter === "week" ? "contained" : "outlined"}
              onClick={() => setFilter("week")}
              sx={{
                fontFamily: "Jua",
                bgcolor: filter === "week" ? "#2BAE9E" : "inherit",
                color: filter === "week" ? "white" : "inherit",
                "&:hover": {
                  bgcolor: "#2BAE9E",
                  color: "white",
                  borderColor: "#2BAE9E",
                },
              }}
            >
              This Week
            </Button>
          </ButtonGroup>

          <Button
            onClick={exportPDF}
            variant="contained"
            sx={{
              ml: 1,
              fontFamily: "Jua",
              bgcolor: "#2BAE9E",
              color: "white",
              "&:hover": { bgcolor: "#279E8F" },
            }}
          >
            Export to PDF
          </Button>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mt: 4, color: "black", fontWeight: 900, fontFamily: "Jua" }}
          >
            Sales Chart
          </Typography>
          <div id="salesChart" ref={chartRef}>
            {" "}
            <Line data={salesData} />
          </div>
        </Container>
      </Box>
    </Box>
  );
};

export default FinancialDashboard;
