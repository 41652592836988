import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  format,
  parseISO,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Modal,
  LinearProgress,
  Backdrop,
  Fade,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sidebar from "./Sidebar";
import { PRIMARY_COLOR } from "./assets/Colours";
const Calendar = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [pets, setPets] = useState({});
  const [clients, setClients] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDayAppointments, setSelectedDayAppointments] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isInvoicing, setIsInvoicing] = useState(false);
  const booleanToYesNo = (value) => (value ? "Yes" : "No");
  useEffect(() => {
    const permissionID = localStorage.getItem("permissionID");
    setHasPermission(permissionID !== "2");
    fetchAppointments();
    fetchPets();
    fetchClients();
  }, []);
  const firebaseBucketURL =
    "https://firebasestorage.googleapis.com/v0/b/clawsandpaws-d515c.appspot.com/o/";
  const fetchAppointments = async () => {
    try {
      const response = await axios.get(
        "https://clawsandpawsapi.azurewebsites.net/api/Appointments"
      );
      setAppointments(response.data);
    } catch (error) {
      console.error("Failed to fetch appointments:", error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        "https://clawsandpawsapi.azurewebsites.net/api/Clients"
      );
      const clientsMap = response.data.reduce(
        (acc, client) => ({ ...acc, [client.clientID]: client }),
        {}
      );
      setClients(clientsMap);
    } catch (error) {
      console.error("Failed to fetch pets:", error);
    }
  };

  const fetchPets = async () => {
    try {
      const response = await axios.get(
        "https://clawsandpawsapi.azurewebsites.net/api/Pets"
      );
      const petsMap = response.data.reduce(
        (acc, pet) => ({ ...acc, [pet.petID]: pet }),
        {}
      );
      setPets(petsMap);
    } catch (error) {
      console.error("Failed to fetch pets:", error);
    }
  };

  const getAppointmentsByDay = (day) => {
    return appointments.filter((appointment) => {
      return (
        appointment.appointmentDate &&
        format(parseISO(appointment.appointmentDate), "yyyy-MM-dd") ===
          format(day, "yyyy-MM-dd") &&
        !appointment.isCancelled
      );
    });
  };
  const getGoogleMapsUrl = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  };
  const handleDayClick = (day) => {
    const dailyAppointments = getAppointmentsByDay(day);
    setSelectedDay(day);
    setSelectedDayAppointments(dailyAppointments);
  };

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(selectedMonth),
    end: endOfMonth(selectedMonth),
  });

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5.2,
    slidesToScroll: 3,
  };
  const sizeMapping = {
    1: "Small",
    2: "Medium",
    3: "Large",
  };
  const createInvoiceData = (appointment, client, pet) => {
    return {
      To: client.name + " " + client.surname,
      Date: new Date().toISOString().split("T")[0],
      DueDate: new Date(new Date().setDate(new Date().getDate() + 7))
        .toISOString()
        .split("T")[0],
      Items: [
        {
          Name: `${appointment.groomingDetails} for ${pet.name}`,
          Quantity: 1,
          UnitCost: pet.price,
        },
      ],
      ClientEmail: client.email,
    };
  };
  const sendInvoice = async (invoiceData) => {
    try {
      const response = await axios.post(
        "https://clawsandpawsapi.azurewebsites.net/api/Invoice/SendInvoice",
        invoiceData
      );
      console.log("Invoice sent successfully:", response.data);
      updateAppointmentAsInvoiceSent();
    } catch (error) {
      console.error("Failed to send invoice:", error);
    }
  };
  const setAsPaid = async () => {
    try {
      const updatedAppointment = {
        ...selectedAppointment,
        isPaid: true,
      };
      setSelectedAppointment(updatedAppointment);
      const response = await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/Appointments/${selectedAppointment.appointmentID}`,
        updatedAppointment
      );
    } catch (error) {
      console.error("Failed to set invoice as paid:", error);
    }
  };
  const updateAppointmentAsInvoiceSent = async () => {
    try {
      const updatedAppointment = {
        ...selectedAppointment,
        invoiceSent: true,
      };
      setSelectedAppointment(updatedAppointment);
      const response = await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/appointments/${selectedAppointment.appointmentID}`,
        updatedAppointment
      );
    } catch (error) {
      console.error("Failed to update appointment:", error);
    }
  };
  const sendInvoicesForSelectedDay = async () => {
    setIsInvoicing(true);
    setProgress(0);

    for (let i = 0; i < selectedDayAppointments.length; i++) {
      const appointment = selectedDayAppointments[i];

      const client = clients[appointment.clientID];
      const pet = pets[appointment.petID];

      if (!client || !pet) {
        console.error(
          `Client or pet details missing for appointment ID ${appointment.appointmentID}`
        );
        continue;
      }

      const invoiceData = createInvoiceData(appointment, client, pet);

      try {
        await axios.post(
          "https://clawsandpawsapi.azurewebsites.net/api/Invoice/SendInvoice",
          invoiceData
        );
        setProgress(((i + 1) / selectedDayAppointments.length) * 100);
      } catch (error) {
        console.error("Failed to send invoice:", error);
      }
    }

    setIsInvoicing(false);
    setShowModal(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
        fontFamily: "Jua, sans-serif",
      }}
    >
      <Sidebar open={mobileOpen} onClose={() => setMobileOpen(!mobileOpen)} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: "hidden" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: "Jua, sans-serif" }}>
            Calendar
          </Typography>
          <FormControl
            sx={{
              width: "200px",
              backgroundColor: PRIMARY_COLOR,
              borderRadius: 1,
            }}
          >
            <InputLabel id="month-select-label" sx={{ color: "black" }}>
              Month
            </InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={format(selectedMonth, "yyyy-MM")}
              onChange={(e) =>
                setSelectedMonth(new Date(e.target.value + "-01"))
              }
              sx={{
                color: "black",
                fontFamily: "Jua, sans-serif",
                ".MuiSvgIcon-root": { color: "black" },
              }}
            >
              {Array.from(
                { length: 12 },
                (_, i) => new Date(new Date().getFullYear(), i)
              ).map((month) => (
                <MenuItem key={month} value={format(month, "yyyy-MM")}>
                  {format(month, "MMMM yyyy")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Card
          sx={{
            padding: 3,
            marginTop: 2,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Slider {...settings}>
            {daysInMonth.map((day) => (
              <div key={day} onClick={() => handleDayClick(day)}>
                <button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 10,
                    padding: "10px 0",
                    minWidth: "180px",
                    height: "80px",
                    backgroundColor: PRIMARY_COLOR,
                    color: "black",
                    fontSize: "16px",
                    fontFamily: "Jua, sans-serif",
                    borderRadius: "15px",
                  }}
                >
                  {format(day, "MMM dd")}
                  <span>{getAppointmentsByDay(day).length} Appointment/s</span>
                </button>
              </div>
            ))}
          </Slider>
        </Card>
        {selectedDayAppointments.length > 0 && (
          <Box sx={{ display: "flex", marginTop: 2, maxHeight: 600 }}>
            <Box sx={{ width: "30%", height: 600, overflowY: "auto" }}>
              <Typography variant="h6" sx={{ fontFamily: "Jua, sans-serif" }}>
                Appointments for {format(selectedDay, "dd MMMM")}:
              </Typography>
              {hasPermission && (
                <Button
                  variant="contained"
                  onClick={() => setShowModal(true)}
                  sx={{
                    fontFamily: "Jua, sans-serif",
                    fontSize: 14,
                    color: "black",
                    backgroundColor: PRIMARY_COLOR,
                    mt: 2,
                    "&:hover": {
                      backgroundColor: PRIMARY_COLOR,
                    },
                  }}
                >
                  Invoice for all appointments today
                </Button>
              )}

              {selectedDayAppointments.map((appointment, index) => (
                <Box
                  key={index}
                  onClick={() => setSelectedAppointment(appointment)}
                  sx={{
                    marginTop: 1.7,
                    padding: 3,
                    backgroundColor: PRIMARY_COLOR,
                    borderRadius: 4,
                    cursor: "pointer",
                    height: 100,
                  }}
                >
                  <Typography
                    sx={{ fontFamily: "Jua, sans-serif", fontSize: 18 }}
                  >{`${
                    pets[appointment.petID]?.name || "Unknown Pet"
                  } - ${format(
                    parseISO(appointment.appointmentDate),
                    "HH:mm"
                  )}`}</Typography>
                  <Typography
                    sx={{ fontFamily: "Jua, sans-serif", fontSize: 18 }}
                  >{`Appointment: ${appointment.groomingDetails}`}</Typography>
                </Box>
              ))}
            </Box>
            {selectedAppointment && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 2,
                    backgroundColor: PRIMARY_COLOR,
                    borderRadius: 5,
                    width: "40%",
                    minHeight: 500,
                    marginRight: 10,
                    marginLeft: 6,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "28px",
                      marginBottom: 4,
                      marginTop: 2,
                    }}
                  >
                    Appointment Information:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Client Name: {clients[selectedAppointment.clientID]?.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Cell Number:{" "}
                    {clients[selectedAppointment.clientID]?.phoneNumber}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Email: {clients[selectedAppointment.clientID]?.email}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Address: {clients[selectedAppointment.clientID]?.address}
                    <IconButton
                      color="primary"
                      onClick={() =>
                        window.open(
                          getGoogleMapsUrl(
                            clients[selectedAppointment.clientID]?.address
                          ),
                          "_blank"
                        )
                      }
                    >
                      <img
                        src="GoogleMaps.png"
                        alt="Google Maps"
                        style={{ width: 24, height: 24 }}
                      />
                    </IconButton>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Start Time:{" "}
                    {format(
                      new Date(selectedAppointment.appointmentDate),
                      "HH:mm"
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Pick up at house:{" "}
                    {booleanToYesNo(selectedAppointment.isPickup)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Drop off at house:{" "}
                    {booleanToYesNo(selectedAppointment.isDropoff)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "20px",
                      marginBottom: 2,
                    }}
                  >
                    Details: {selectedAppointment.groomingDetails}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "40%",
                    padding: 0,
                    backgroundColor: PRIMARY_COLOR,
                    borderRadius: 5,
                    wordSpacing: 8,
                    minHeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "32px",
                      marginTop: 3,
                    }}
                  >
                    {pets[selectedAppointment.petID]?.name || "Unknown Pet"}
                  </Typography>
                  <img
                    src={`${firebaseBucketURL}${
                      pets[selectedAppointment.petID]?.photo
                    }?alt=media`}
                    alt="Pet"
                    style={{
                      marginBottom: 20,
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "24px",
                      marginBottom: 2,
                    }}
                  >
                    Breed: {pets[selectedAppointment.petID]?.breed}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "24px",
                      marginBottom: 2,
                    }}
                  >
                    Size:{" "}
                    {sizeMapping[pets[selectedAppointment.petID]?.sizeID] ||
                      "Unknown Size"}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Jua, sans-serif",
                      fontSize: "22px",
                      marginBottom: 2,
                    }}
                  >
                    Pet Notes: {pets[selectedAppointment.petID]?.notes}
                  </Typography>
                  {hasPermission && (
                    <Box sx={{ width: "100%", display: "flex" }}>
                      {!selectedAppointment.invoiceSent ? (
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: "0 0 10px 10px",
                            width: "100%",
                            boxShadow: 3,
                            backgroundColor: PRIMARY_COLOR,
                            color: "#333",
                            "&:hover": {
                              backgroundColor: PRIMARY_COLOR,
                            },
                          }}
                          onClick={() =>
                            sendInvoice(
                              createInvoiceData(
                                selectedAppointment,
                                clients[selectedAppointment.clientID],
                                pets[selectedAppointment.petID]
                              )
                            )
                          }
                        >
                          Send Invoice
                        </Button>
                      ) : !selectedAppointment.isPaid ? (
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: "0 10px",
                            width: "100%",
                            backgroundColor: PRIMARY_COLOR,
                            color: "#333",
                            "&:hover": {
                              backgroundColor: PRIMARY_COLOR,
                            },
                          }}
                          onClick={setAsPaid}
                        >
                          Set as Paid
                        </Button>
                      ) : (
                        <Typography
                          sx={{
                            marginBottom: 1,
                            mt: 2,
                            textAlign: "center",
                            typography: { sm: "h6", xs: "subtitle1" },
                            width: "100%",
                          }}
                        >
                          Payment Received
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={showModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
          >
            {!isInvoicing ? (
              <Typography variant="h6" component="h2">
                You are about to send invoices to{" "}
                {selectedDayAppointments.length} clients.
              </Typography>
            ) : (
              <Typography variant="h6" component="h2">
                You are sending invoices for all appointments today.
              </Typography>
            )}
            {!isInvoicing && (
              <Typography sx={{ mt: 2, mb: 2 }}>
                Click "Confirm & Send" to proceed.
              </Typography>
            )}

            {isInvoicing ? (
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ width: "100%", mt: 2 }}
              />
            ) : (
              <Button
                variant="contained"
                onClick={sendInvoicesForSelectedDay}
                sx={{
                  fontFamily: "Jua, sans-serif",
                  fontSize: 14,
                  color: "black",
                  backgroundColor: PRIMARY_COLOR,
                  mt: 2,
                  "&:hover": {
                    backgroundColor: PRIMARY_COLOR,
                  },
                }}
              >
                Confirm & Send
              </Button>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Calendar;
