// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC06j46MrKo94ZaASdwo1ZQVEfhw2tYXEg",
  authDomain: "clawsandpaws-d515c.firebaseapp.com",
  projectId: "clawsandpaws-d515c",
  storageBucket: "clawsandpaws-d515c.appspot.com",
  messagingSenderId: "174161969301",
  appId: "1:174161969301:android:735c794758e7242598db9c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the Firebase Storage instance
export const storage = getStorage(app);
