import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/ModeEdit";
import ResetIcon from "@mui/icons-material/Password";
import DeactivateIcon from "@mui/icons-material/Delete";

import { PRIMARY_COLOR } from "./assets/Colours";
import AddStaff from "./AddStaff";
import EditStaff from "./EditStaff";
import DeleteStaff from "./DeleteStaff";
import ResetPassword from "./ResetPassword";

const StyledTableCell = ({ children }) => (
  <TableCell
    sx={{ fontFamily: "Jua", borderRight: "1px solid white", fontSize: "15px" }}
  >
    {children}
  </TableCell>
);

const StyledTableCellHeader = ({ children }) => (
  <TableCell
    sx={{
      fontFamily: "Jua !important",
      borderRight: "1px solid white !important",
      backgroundColor: `${PRIMARY_COLOR} !important`,
      color: "black !important",
      zIndex: 2,
      fontSize: "20px",
    }}
  >
    {children}
  </TableCell>
);

const tableHeaders = [
  "Name",
  "Last Name",
  "Cell Number",
  "Email",
  "User Type",
  "Options",
];

const Staff = ({ businessID }) => {
  const navigate = useNavigate();
  const [staff, setStaff] = useState([]);
  const [creds, setCreds] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [selectedUserCredID, setSelectedCredUserID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy] = useState("firstName");

  const applyStaffFilterAndSort = (
    staff,
    searchTerm,
    sortBy,
    sortDirection
  ) => {
    return staff
      .filter((user) => user.isActive)
      .filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const valueA = a[sortBy]?.toLowerCase() || "";
        const valueB = b[sortBy]?.toLowerCase() || "";
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });
  };
  const filteredAndSortedStaff = useMemo(() => {
    return applyStaffFilterAndSort(staff, searchTerm, sortBy, sortDirection);
  }, [staff, searchTerm, sortBy, sortDirection]);
  const fetchData = async () => {
    try {
      const staffResponse = await axios.get(
        `https://clawsandpawsapi.azurewebsites.net/api/Users`
      );
      const userCredentials = await axios.get(
        "https://clawsandpawsapi.azurewebsites.net/api/Credentials/UserCredentials"
      );
      const credData = userCredentials.data;
      const staffData = staffResponse.data;

      setStaff(staffData);
      setCreds(credData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const permissionID = localStorage.getItem("permissionID");
    if (permissionID !== "1") {
      navigate("/dashboard");
      return;
    }
    fetchData();
  });

  const handleAddStaff = () => {
    setOpenAddDialog(true);
  };

  const getCredentialID = (userID) => {
    const userCredential = creds.find((cred) => cred.userID === userID);
    return userCredential ? userCredential.credentialID : null;
  };

  const handleResetPass = (userID) => {
    const credentialID = getCredentialID(userID);
    if (credentialID) {
      setSelectedCredUserID(credentialID);
      setOpenResetDialog(true);
    } else {
      console.error("Credential ID not found for userID:", userID);
    }
  };

  const handleEditStaff = (userID) => {
    setSelectedUserID(userID);
    setOpenEditDialog(true);
  };

  const handleDeleteStaff = (userID) => {
    setSelectedUserID(userID);
    setOpenDeleteDialog(true);
  };

  const handleDialogClose = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenResetDialog(false);
    fetchData();
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2, display: "flex", pr: "100px" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 3,
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "black",
                fontFamily: "Jua",
                position: "absolute",

                left: "350px",
                fontWeight: 900,
                textAlign: "left",
              }}
            >
              Staff
            </Typography>
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <TextField
              label="Search Staff Names"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ flex: 1, mr: 2 }}
            />
            <Button
              onClick={() =>
                setSortDirection(sortDirection === "asc" ? "desc" : "asc")
              }
              sx={{
                bgcolor: PRIMARY_COLOR,
                color: "black",
                fontFamily: "Jua",
                "&:hover": { bgcolor: "darken(PRIMARY_COLOR, 0.2)" },
              }}
            >
              Sort {sortDirection === "asc" ? "Descending" : "Ascending"}
            </Button>
          </Box>

          <Box
            sx={{
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              display: "flex",
              overflowX: "auto",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ width: "100%", maxHeight: "80vh", overflowY: "auto" }}
            >
              <Table stickyHeader sx={{ minWidth: "650px" }}>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <StyledTableCellHeader key={header}>
                        {header}
                      </StyledTableCellHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAndSortedStaff.map((users, index) => (
                    <TableRow
                      key={staff.userID}
                      sx={{
                        bgcolor: index % 2 === 0 ? "#f5f5f5" : PRIMARY_COLOR,
                      }}
                    >
                      <StyledTableCell>{users.firstName}</StyledTableCell>
                      <StyledTableCell>{users.lastName}</StyledTableCell>
                      <StyledTableCell>{users.phoneNumber}</StyledTableCell>
                      <StyledTableCell>{users.email}</StyledTableCell>
                      <StyledTableCell>
                        {users.permissionID === 1
                          ? "Admin"
                          : users.permissionID === 2
                          ? "User"
                          : "Unknown"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{ color: "green" }}
                            onClick={() => handleEditStaff(users.userID)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset Password">
                          <IconButton
                            sx={{ color: "blue" }}
                            onClick={() => handleResetPass(users.userID)}
                          >
                            <ResetIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Deactivate">
                          <IconButton
                            sx={{ color: "red" }}
                            onClick={() => handleDeleteStaff(users.userID)}
                          >
                            <DeactivateIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="h4"
              gutterBottom
              sx={{
                "&:hover": {
                  bgcolor: "2BAE9E",
                  color: "#25467B",
                },
                left: "330px",
                position: "absolute",
                bgcolor: PRIMARY_COLOR,
                fontFamily: "Jua",
                color: "black",
                mt: 8,
              }}
              onClick={handleAddStaff}
            >
              Add Staff Member
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog open={openAddDialog} onClose={handleDialogClose}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <AddStaff businessID={businessID} onStaffAdded={handleDialogClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit Staff</DialogTitle>
        <DialogContent>
          <EditStaff
            userID={selectedUserID}
            onStaffEdited={handleDialogClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openResetDialog} onClose={handleDialogClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <ResetPassword
            userID={selectedUserCredID}
            onPasswordReset={handleDialogClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDialogClose}>
        <DialogContent>
          <DeleteStaff
            userID={selectedUserID}
            onStaffDeleted={handleDialogClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Staff;
