import React, { useState, useEffect } from "react";
import axios from "axios";
import { PRIMARY_COLOR } from "./assets/Colours";
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
} from "@mui/material";

const EditStaff = ({ userID, onStaffEdited }) => {
  const [error, setError] = useState("");
  const [staffData, setStaffData] = useState({
    
    userID: userID,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    isActive: false,
    permissionID: "",
  });

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get(
          `https://clawsandpawsapi.azurewebsites.net/api/Users/${userID}`
        );

        setStaffData(response.data);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    fetchStaff();
  }, [userID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaffData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setStaffData((prevData) => ({
      ...prevData,
      isActive: e.target.checked,
    }));
  };

  const handleEditStaff = async () => {
    try {
      await axios.put(
        `https://clawsandpawsapi.azurewebsites.net/api/Users/${userID}`,
        staffData
      );
      onStaffEdited();
    } catch (error) {
      console.error("Error editing staff:", error);
      setError("Failed to edit user.");
    }
  };

  return (
    <Box>
      <TextField
        label="Staff Name"
        name="firstName"
        value={staffData.firstName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Last Name"
        name="lastName"
        value={staffData.lastName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={staffData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={staffData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      <Select
        label="User Type"
        name="permissionID"
        value={staffData.permissionID}
        onChange={handleChange}
        fullWidth
        displayEmpty
        margin="normal"
      >
        <MenuItem value="" disabled>
          Select User Type
        </MenuItem>
        <MenuItem value="2">User</MenuItem>
        <MenuItem value="1">Admin</MenuItem>
      </Select>
      {error && <p style={{ color: "red" }}>{error}</p>}

      <Button
        variant="contained"
        color="primary"
        onClick={handleEditStaff}
        sx={{
          mt: 2,
          bgcolor: PRIMARY_COLOR,
          color: "black",
          fontFamily: "Jua",
          fontWeight: 900,
          textAlign: "left",
          "&:hover": {
            bgcolor: "white",
            color: "#25467B",
            transition: "transform 0.3s",
            transform: "translateY(-5px)",
          },
          "& .MuiListItemIcon-root": {
            color: "#F1AC36",
          },
        }}
      >
        Edit Staff
      </Button>
    </Box>
  );
};

export default EditStaff;
